import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

const url = process.env.REACT_APP_API_BASE_URL || '/api';

// Fetch user's favorite candidates
export const fetchFavorites = async (userId) => {
  const { data } = await axios.get(`${url}/user/${userId}/favorites`);
  return data;
};

// Update user's favorite candidates
export const updateFavorites = async ({ userId, favorites }) => {

  
  const favoriteIds = favorites.map(fav => fav._id || fav);
  const { data } = await axios.put(`${url}/user/${userId}/favorites`, { favorites: favoriteIds });
  return data.favorites;
};

// Custom hook to fetch favorites
export const useFetchFavorites = (userId) => {
  return useQuery({
    queryKey: ['favorites', userId],
    queryFn: () => fetchFavorites(userId),
    enabled: !!userId, // Ensures that query only runs when userId is available
  });
};

// Custom hook to update favorites
export const useUpdateFavorites = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables) => updateFavorites(variables),
    onSuccess: (_, variables) => {
      // Invalidate and refetch the favorites data after mutation
      queryClient.invalidateQueries(['favorites', variables.userId]);
    },
  });
};
